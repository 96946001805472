import request from '@/utils/request'

// 敏感词校验
export function checkSensitiveWords( data ) {
  const msg = encodeURIComponent( data.msg )
  return request( {
    url : `/api/v1/common/checkSensitiveWords?message=${msg}`,
    headers : {
      isToken : false
    },
    method : 'post',
    data : data
  } )
  // return Promise.resolve({"msg":"操作成功","code":200})
}

// 登录方法
export function login( username, password, code, uuid, captchaCode ) {
  const data = {
    username,
    password,
    code,
    uuid,
    captchaCode
  }
  return request( {
    url : '/login',
    headers : {
      isToken : false
    },
    method : 'post',
    data : data
  } )
}

export function loginByCode( username, password, code, uuid ) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request( {
    url : '/loginByCode',
    headers : {
      isToken : false
    },
    method : 'post',
    data : data
  } )
}

export function sendSms( data ) {
  return request( {
    url : '/sendSms',
    method : 'post',
    data : data
  } )
}

export function getLoginQrImg( env = null ) {
  return request( {
    url : '/getMiniPgQrImage?env=' + env,
    headers : {
      isToken : false
    },
    method : 'get'
  } )
}

// 注册
export function register( data ) {
  return request( {
    url : '/registerByCode',
    headers : {
      isToken : false
    },
    method : 'post',
    data : data
  } )
}

export function checkSceneId( sceneId ) {
  let referer = localStorage.getItem( 'referrer' )
  if ( !referer ) {
    referer = window.location.href
  }

  const data = {
    sceneId,
    registerReferer : referer
  }
  return request( {
    url : '/checkSceneId',
    headers : {
      isToken : false
    },
    method : 'post',
    data : data
  } )
}

// 获取用户详细信息
export function getInfo() {
  return request( {
    url : '/getInfo',
    method : 'get'
  } )
}

export function logout() {
  return request( {
    url : '/logout',
    method : 'post'
  } )
}

// 注册
export function resetPwdByMobile( data ) {
  return request( {
    url : '/resetPwdByMobile',
    headers : {
      isToken : false
    },
    method : 'post',
    data : data
  } )
}

export function feedbackAdd( data ) {
  return request( {
    url : '/feedback/add',
    headers : {
      isToken : false
    },
    method : 'post',
    data : data
  } )
}

export function getPortalSetting() {
  return request( {
    url : '/system/config/configKey/portalSetting',
    method : 'get'
  } )
}

export function getCustomPageSetting() {
  return request( {
    url : '/system/config/configKey/customPageSetting',
    method : 'get'
  } )
}

export function getValid() {
  return request( {
    url : '/getValid',
    method : 'get'
  } )
}

// 大屏
export function getCountProvince( data ) {
  return request( {
    url : `/idis/secondNode/countProvince?topNode=${data.topNode}`,
    method : 'get'
  } )
}

export function getListAll( data ) {
  return request( {
    url : `/idis/secondNode/listAll?topNode=${data.topNode}&province=${data.province}&city=${data.city}&prefixOrCompanyLike=${data.prefixOrCompanyLike}`,
    method : 'get'
  } )
}

export async function getSnmsInfoById( id ) {
  const resp = await request( {
    url : '/idis/secondNode/listAll?id=' + id
  } )
  return resp?.rows?.[0]
}

export function captchaImage() {
  return request( {
    url : `/captchaImage`,
    method : 'get'
  } )
}

// 扫码总数
export function getScanAll( data ) {
  return request( {
    url : `/statistical/bigScreen/scanCodeAllCount`,
    method : 'get'
  } )
}

// 省数据
export function getScanCountByProvince( data ) {
  return request( {
    url : `/statistical/bigScreen/scanCountByProvince?province=${data.province}`,
    method : 'get'
  } )
}

// 扫码总数不分页
export function getScanCodeByProvince( data ) {
  return request( {
    url : `/statistical/bigScreen/scanCodeByProvince?province=${data.province}`,
    method : 'get'
  } )
}

// 分页
export function getScanCodeByProvinceAll( data ) {
  return request( {
    url : `/statistical/bigScreen/scanCodeByProvince?province=${data.province}&city=${data.city}&pageNum=100&pageIndex=1`,
    method : 'get'
  } )
}
export function scanCountByCount( data ) {
  return request( {
    url : `/statistical/bigScreen/scanCountByCount?dateType=${data}`,
    method : 'get'
  } )
}
// 标签类型
export const getLabelPrintTypes = () => {
  return request( {
    url : '/system/dict/data/list?dictType=label_category',
    method : 'get'
  } )
}
// 标签库列表
export const getPublicLabelList = ( data ) => {
  return request( {
    url : `/labelPrint/publicLabel/list?categoryId=${data.dictValue}&printMethod=${data.printMethod}&printSize=${data.printSize}&pageNum=${data.pageNum}&pageSize=99999999`,
    method : 'get'
  } )
}
export const getPrintMethod = () => {
  return request( {
    url : '/system/dict/data/list?dictType=print_method',
    method : 'get'
  } )
}
export const getPrintSize = () => {
  return request( {
    url : '/system/dict/data/list?dictType=print_size',
    method : 'get'
  } )
}


import { isMobile } from '@/utils/utils'
import moment from 'moment'
import eventBus from '@/utils/event-bus'
import { checkSensitiveWords, getLoginQrImg } from '@/api/login'
import {
  getQrBase64,
  getCodeStatus,
  uploadActionImg,
  uploadActionFile,
  uploadActionAudio,
  uploadActionVideo,
  saveToUser, getStaticCodeInfo
} from '@/api/template'
import BeautifyQr from '../../othComponents/qrBeautySet'
import qrcodeParser from 'qrcode-parser'
import {
  BrowserMultiFormatReader,
  ChecksumException,
  FormatException
} from '@zxing/library'
const cmsNewsUrl = process.env.CMS_NEWS_URL
export default {
  name : 'Index',
  components : {
    BeautifyQr
  },
  filters : {
    dataFormat( text ) {
      if ( text !== null ) {
        return Number( text / 1024000 ).toFixed( 1 ) + 'MB'
      }
    }
  },
  layout : 'base',
  scrollToTop : true,
  // async asyncData(context) {
  // let newsData = null
  // let questionList = null
  // let secondNodes = null
  // try {
  //   const resA = await listNewsList()
  //   newsData = resA.ret
  //   // console.log('newsData>>',resA)
  //   const resB = await openListAnswer({
  //     pageNum: 1,
  //     pageSize: 3
  //   })
  //   questionList = resB.rows
  //   const resC = await getSecondNodes()
  //   secondNodes = resC.rows
  // } catch {
  //   // ignore error
  // }
  // return { newsData, questionList, secondNodes }
  // },
  data() {
    const self = this
    return {
      idisCode : '',
      idisFlag : true,
      idisSync : true,
      codeReader : new BrowserMultiFormatReader(),
      videoInputDevices : [],
      currentVideoInputDevice : {},
      decodeResult : undefined,
      cmsNewsUrl,
      classOption : {
        step : 0.5
      },
      uploadJieMaUrl : process.env.BASE_URL + '/file/upload/image?pageFunc=解码',
      sxtIcon : require( '@/assets/images/indexHome/sxt.png' ),
      textiCON : require( '@/assets/images/indexHome/jiema - 副本.png' ),
      jieMaImg : require( '@/assets/images/indexHome/jiema.png' ),
      qr_bgImg : require( '@/assets/images/indexHome/img_qr.png' ),
      upIcon : require( '@/assets/images/indexHome/1u.png' ),
      twIcon : require( '@/assets/images/indexHome/icon_2.png' ),
      img_icon : require( '@/assets/images/indexHome/imgIcon.png' ),
      file_icon : require( '@/assets/images/indexHome/fileIcon.png' ),
      audio_icon : require( '@/assets/images/indexHome/audioIcon.png' ),
      video_icon : require( '@/assets/images/indexHome/videoIcon.png' ),
      audios_icon : require( '@/assets/images/indexHome/audiosIcon.png' ),
      videos_icon : require( '@/assets/images/indexHome/videosIcon.png' ),
      newsIcon : require( '@/assets/images/indexHome/1.png' ),
      qaIcon : require( '@/assets/images/indexHome/2.png' ),
      nodesIcon : require( '@/assets/images/indexHome/3.png' ),
      btn_re : require( '@/assets/images/indexHome/btn_re.png' ),
      tIcon : require( '@/assets/images/indexHome/policy3.png' ),
      qa1Icon : require( '@/assets/images/indexHome/icon_admin.png' ),
      qa2Icon : require( '@/assets/images/indexHome/qa.png' ),
      text : '',
      qrImgUrlText : '',
      typeList : [
        {
          name : '文本',
          key : 'text',
          isActive : true
        },
        {
          name : '图片',
          key : 'image',
          isActive : false
        }, {
          name : '名片',
          key : 'callingCard',
          isActive : false
        }, {
          name : '网址',
          key : 'link',
          isActive : false
        }, {
          name : '文件',
          key : 'file',
          isActive : false
        }, {
          name : '电话',
          key : 'phone',
          isActive : false
        }, {
          name : '音频',
          key : 'audio',
          isActive : false
        }, {
          name : '视频',
          key : 'video',
          isActive : false
        },
        {
          name : '地图',
          key : 'map',
          isActive : false
        }, {
          name : 'wifi',
          key : 'wifi',
          isActive : false
        },
        {
          name : '表单',
          key : 'formPage',
          isActive : false
        }
      ],
      activeName : 'text',
      isCreate : false,
      isShowQr : false,
      isUplaoding : false,
      qrImgUrl : '',
      loadingUp : false,
      maploading : false,
      // image
      dragIndex : '',
      showPicSList : [
        {
          value : 1,
          label : '平铺'
        },
        {
          value : 2,
          label : '2s轮播'
        },
        {
          value : 3,
          label : '3s轮播'
        },
        {
          value : 5,
          label : '5s轮播'
        }
      ],
      showPicTList : [
        {
          value : 1,
          label : '撑满'
        },
        {
          value : 2,
          label : '留白'
        }
      ],
      upLoadImgs : {
        config : {
          s : 1, // 1平铺 2 2s轮播 3 3s轮播 5 5s轮播
          t : 1 // 1:撑满2留白
        },
        fileList : []
      },
      imgFiles : [],
      // file
      upLoadFiles : {
        fileList : []
      },
      // audio
      upLoadAudios : {
        fileList : []
      },
      // video
      upLoadVideos : {
        fileList : []
      },
      // callingCard
      qrForm : {
        name : '',
        mobile : '',
        company : '',
        position : '',
        email : '',
        link : '',
        address : '',
        linkType : 10,
        link2 : '',
        phone : ''
      },
      rules : {
        name : [
          { required : true, message : '请输入姓名', trigger : 'blur' }
        ],
        ssid : [
          { required : true, message : '请输入wifi名称', trigger : 'blur' }
        ],
        type : [
          { required : true, message : '请选择加密方式', trigger : 'change' }
        ],
        mobile : [
          {
            pattern : /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/,
            message : '请输入正确的手机号码',
            trigger : 'blur'
          },
          { required : true, message : '请输入手机号码', trigger : 'blur' }
        ],
        phone : [
          {
            pattern : /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/,
            message : '请输入正确的手机号码',
            trigger : 'blur'
          }
        ],
        email : [
          {
            pattern : /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
            message : '请输入正确的邮箱地址',
            trigger : 'blur'
          }
        ],

        link : [
          {
            pattern : /^((http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?)/,
            message : '请输入正确的网址地址含（http:// ，https://）',
            trigger : 'blur'
          }
        ],
        linkType : [
          { required : true, message : '请选择链接类型', trigger : 'blur' }
        ],
        link2 : [
          { max : 255, message : '长度在不能超过255个字符', trigger : 'blur' },
          {
            pattern : /^((http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?)/,
            message : '请输入正确的网址地址含（http:// ，https://）',
            trigger : 'blur'
          }
        ]
      },
      isEmail : false,
      isLink : false,
      isAddress : false,
      dropdownList : [
        {
          key : '1',
          name : '邮箱'
        },
        {
          key : '2',
          name : '网址'
        },
        {
          key : '3',
          name : '地址'
        }
      ],
      wifiOptions : [
        {
          value : 'WAP',
          label : 'WAP'
        }, {
          value : 'WEP',
          label : 'WEP'
        }, {
          value : 'No encryption',
          label : '无加密'
        }
      ],
      wifiForm : {
        ssid : '',
        password : '',
        type : 'WAP'
      },
      // 地图生码
      lng : 0,
      lat : 0,
      loaded : false,
      plugin : [{
        enableHighAccuracy : true, // 是否使用高精度定位，默认:true
        timeout : 100, // 超过10秒后停止定位，默认：无穷大
        maximumAge : 0, // 定位结果缓存0毫秒，默认：0
        convert : true, // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
        showButton : true, // 显示定位按钮，默认：true
        buttonPosition : 'LB', // 定位按钮停靠位置，默认：'LB'，左下角
        showMarker : true, // 定位成功后在定位到的位置显示点标记，默认：true
        showCircle : true, // 定位成功后用圆圈表示定位精度范围，默认：true
        panToLocation : true, // 定位成功后将定位到的位置作为地图中心点，默认：true
        zoomToAccuracy : true, // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
        extensions : 'all',
        pName : 'Geolocation',
        events : {
          init( o ) {
            // o 是高德地图定位插件实例
            o.getCurrentPosition( ( status, result ) => {
              console.log( result )
              if ( result && result.position ) {
                self.lng = result.position.lng
                self.lat = result.position.lat
                self.center = [self.lng, self.lat]
                self.loaded = true
                self.$nextTick()
              }
            } )
          },
          click( e ) {
            const { lng, lat } = e.lnglat
            self.lng = lng
            self.lat = lat

            // 这里通过高德 SDK 完成。
            // eslint-disable-next-line no-undef
            const geocoder = new AMap.Geocoder( {
              radius : 1000,
              extensions : 'all'
            } )
            geocoder.getAddress( [lng, lat], function( status, result ) {
              if ( status === 'complete' && result.info === 'OK' ) {
                if ( result && result.regeocode ) {
                  self.address = result.regeocode.formattedAddress
                  self.$nextTick()
                }
              }
            } )
          }
        }
      }],
      events : {
        init( o ) {
          console.log( '初始化高德地图1', o )
        },
        click( e ) {
          self.mapObj = {
            longitude : '',
            latitude : '',
            address : ''
          }
          // eslint-disable-next-line no-undef
          const geocoder = new AMap.Geocoder( {} )

          self.longitude = e.lnglat.lng
          self.latitude = e.lnglat.lat
          const lonAndlat = [self.longitude, self.latitude]
          console.log( self.longitude + ',' + self.latitude )
          self.markers = [lonAndlat]
          geocoder.getAddress( lonAndlat, function( status, result ) {
            if ( result.regeocode ) {
              document.querySelector( '.search-box-wrapper input' ).value = result.regeocode.formattedAddress
              self.$message( result.regeocode.formattedAddress )
              self.mapObj = {
                longitude : e.lnglat.lng,
                latitude : e.lnglat.lat,
                address : result.regeocode.formattedAddress
              }
            } else {
              self.$message( {
                type : 'warning',
                message : '查询地址失败'
              } )
            }
          } )
        }
      },
      mapObj : {
        longitude : '',
        latitude : '',
        address : ''
      },
      // 高德地图搜索需要
      markEvents : {
        click( e ) {
          self.mapObj = {
            longitude : '',
            latitude : '',
            address : ''
          }
          // eslint-disable-next-line no-undef
          const geocoder = new AMap.Geocoder( {} )

          self.longitude = e.lnglat.lng
          self.latitude = e.lnglat.lat
          const lonAndlat = [self.longitude, self.latitude]
          console.log( self.longitude + ',' + self.latitude )
          self.markers = [lonAndlat]
          geocoder.getAddress( lonAndlat, function( status, result ) {
            if ( result.regeocode ) {
              // document.querySelector(".search-box-wrapper input").value = result.regeocode.formattedAddress
              self.$message( result.regeocode.formattedAddress )
              self.mapObj = {
                longitude : e.lnglat.lng,
                latitude : e.lnglat.lat,
                address : result.regeocode.formattedAddress
              }
            } else {
              self.$message( {
                type : 'warning',
                message : '查询地址失败'
              } )
            }
          } )
        }
      },
      markers : [],
      searchOption : {},
      mapCenter : [120.929348, 31.965939],
      newsData : null,
      questionList : null,
      secondNodes : null,
      downLoadSet : {
        size : 300,
        type : 'png'
      },
      qrCreateList : [],
      qaTabVal2 : '1',
      jieMaType : 'first',
      jieMaDia : false,
      jieMaInfo : '',
      jieMaUrl : '',
      errorTips : '内容超150字，不易扫描',
      sxtTips : '使用笔记本电脑的用户，可在手机上打开支付宝收款码、微信二维码等页面对准电脑摄像头，快速解码。',
      openVideo : false,
      qrParamValue : {},
      codeId : ''
    }
  },

  computed : {},
  watch : {
    currentVideoInputDevice : function() {
      this.decodeFromInputVideo()
    },
    decodeResult : function() {
      this.successDecode()
    }
  },
  created() {
    // this.listNewsList()
    // this.getQuestions()
    // this.getSecondNodes()
  },
  mounted() {
    eventBus.$emit( 'getPageData', true )
    const _this = this
    const ua = isMobile()
    if ( process.browser ) {
      // if (this.$route.query.pc) {
      //   const dom = document.getElementById('topC')
      //   const dom1 = document.getElementById('booC')
      //   dom.style.width = '1200px'
      //   dom1.style.width = '1200px'
      // }
      if ( ua && !this.$route.query.pc ) {
        window.location.href = `/redirect/mobile`
      }
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        const qrImg = localStorage.getItem( 'loginQrImg' )
        const qrScene = localStorage.getItem( 'loginQrScene' )

        if ( qrImg == null || qrImg == undefined || qrImg == '' || qrScene == null || qrScene == undefined || qrScene ==
          '' ) {
          const r = getLoginQrImg( process.env.MINI_PG_VERSION )
          r.then( ( res ) => {
            if ( res.code == 200 ) {
              localStorage.setItem( 'loginQrImg', res.data )
              localStorage.setItem( 'loginQrScene', res.scene )
            }
          } )
        }
      }
    }
    this.qrCreateList = JSON.parse( JSON.stringify( this.$store.getters.qrList ) )
    eventBus.$on( 'loginOver', k => {
      if ( _this.$store.getters.token ) {
        _this.closedLogin()
      }
    } )
  },

  methods : {
    async getCodeStatusInfo() {
      if ( this.codeId ) {
        await getStaticCodeInfo( this.codeId ).then( res => {
          if ( res.data ) {
            this.idisCode = res.data.idisCode
          }
        } )
      }
    },
    async idisFlagChange( v ) {
      if ( !this.idisCode ) {
        await this.getCodeStatusInfo()
      }
      this.getQrBase64( true )
    },
    openLogin() {
      eventBus.$emit( 'loginOrRegister', 'openLogin' )
    },
    closedLogin() {
      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        if ( this.qrCreateList.length > 0 ) {
          setTimeout( () => {
            this.saveQrToUser()
          }, 800 )
        }
      }
    },
    changeTypes( type ) {
      this.typeList.map( i => {
        if ( i.key == type ) {
          i.isActive = true
          this.activeName = type
          // if (i.key === '7') {
          //   this.$router.push('/templateLibrary')
          //   eventBus.$emit('activeIndex', '3')
          // }
        } else {
          i.isActive = false
        }
      } )
      this.resetCode()
    },
    // 敏感词校验
    checkSensitiveWords( data ) {
      const p = {
        msg : data
      }
      checkSensitiveWords( p ).then( res => {
        if ( res.code == 200 ) {
          this.qrCode()
        }
      } )
    },

    // 生码
    createQrCode() {
      if ( this.activeName === 'text' ) {
        if ( !this.$store.getters.token || this.$store.getters.token == undefined ) {
          eventBus.$emit( 'loginOrRegister', 'openLogin' )
          return
        }
        this.checkSensitiveWords( this.text )
      } else if ( this.activeName === 'callingCard' ) {
        if ( !this.$store.getters.token || this.$store.getters.token == undefined ) {
          eventBus.$emit( 'loginOrRegister', 'openLogin' )
          return
        }

        if ( this.qrForm.mobile.length + this.qrForm.link.length + this.qrForm.email.length + this.qrForm.name.length +
          this.qrForm.company.length + this.qrForm.position.length + this.qrForm.address.length > 300 ) {
          this.$message( {
            type : 'warning',
            message : '总字符超过300个字符，无法生码'
          } )
          return
        }
        this.text = `MECARD:N:${this.qrForm.name};TEL:${this.qrForm.mobile};TITLE:${this.qrForm.position};URL:${this.qrForm.link};EMAIL: ${this.qrForm.email};ORG:${this.qrForm.company};ADR:${this.qrForm.address}`
        this.$refs['qrForm'].validate( valid => {
          if ( valid ) {
            this.checkSensitiveWords( this.text )
          }
        } )
        return
      } else if ( this.activeName === 'phone' ) {
        if ( !this.$store.getters.token || this.$store.getters.token == undefined ) {
          eventBus.$emit( 'loginOrRegister', 'openLogin' )
          return
        }
        this.$refs['qrForm'].validate( valid => {
          if ( valid ) {
            this.text = this.qrForm.phone
            this.qrCode()
          }
        } )
      } else if ( this.activeName === 'link' ) {
        if ( !this.$store.getters.token || this.$store.getters.token == undefined ) {
          eventBus.$emit( 'loginOrRegister', 'openLogin' )
          return
        }
        this.$refs['qrForm'].validate( valid => {
          if ( valid ) {
            this.text = this.qrForm.link2
            this.checkSensitiveWords( this.text )
          }
        } )
      } else if ( this.activeName === 'image' || this.activeName === 'file' || this.activeName === 'audio' ||
        this.activeName === 'video' || this.activeName === 'map' || this.activeName === 'wifi' ) {
        if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
          if ( this.activeName == 'image' ) {
            const obj = JSON.stringify( this.upLoadImgs )
            this.text = `${window.location.origin}/showPic?o=${obj}`
          } else if ( this.activeName == 'file' ) {
            const obj = JSON.stringify( this.upLoadFiles )
            this.text = `${window.location.origin}/showFiles?o=${obj}`
          } else if ( this.activeName == 'audio' ) {
            const obj = JSON.stringify( this.upLoadAudios )
            this.text = `${window.location.origin}/showAudios?o=${obj}`
          } else if ( this.activeName == 'video' ) {
            const obj = JSON.stringify( this.upLoadVideos )
            this.text = `${window.location.origin}/showVideos?o=${obj}`
          } else if ( this.activeName == 'map' ) {
            this.text = `${window.location.origin}/showMap?o=${JSON.stringify( this.mapObj )}`
            this.maploading = true
          } else if ( this.activeName == 'wifi' ) {
            this.text = `${window.location.origin}/showWifi?o=${JSON.stringify( this.wifiForm )}`
          }
          this.qrCode()
        } else {
          eventBus.$emit( 'loginOrRegister', 'openLogin' )
        }
      }
    },
    // 重置
    resetCode() {
      this.text = ''
      this.decodeQrTxt = ''
      this.isShowQr = false
      this.isCreate = false
      this.isEmail = false
      this.isLink = false
      this.isAddress = false
      this.maploading = false
      this.imgFiles = []
      this.dropdownList = [
        {
          key : '1',
          name : '邮箱'
        },
        {
          key : '2',
          name : '网址'
        },
        {
          key : '3',
          name : '地址'
        }
      ]
      this.markers = []
      this.mapObj = {
        longitude : '',
        latitude : '',
        address : ''
      }
      this.wifiForm = {
        ssid : '',
        password : '',
        type : 'WAP'
      }

      if ( this.$refs.imgUpload ) {
        this.$refs.imgUpload.clearFiles()
      }
      if ( this.$refs.uploadFile ) {
        this.$refs.uploadFile.clearFiles()
      }
      if ( this.$refs.uploadAudio ) {
        this.$refs.uploadAudio.clearFiles()
      }
      if ( this.$refs.qrForm ) {
        this.$refs.qrForm.clearValidate()
      }
      this.qrForm = {
        name : '',
        mobile : '',
        company : '',
        position : '',
        email : '',
        link : '',
        address : '',
        link2 : '',
        phone : ''
      }
      this.upLoadImgs = {
        config : {
          s : 1, // 1平铺 2 2s轮播 3 3s轮播 5 5s轮播
          t : 1 // 1:撑满2留白
        },
        fileList : []
      }
      this.upLoadFiles = {
        fileList : []
      }
      this.upLoadAudios = {
        fileList : []
      }
      this.upLoadVideos = {
        fileList : []
      }
      eventBus.$emit( 'resetQrCss', '1' )
    },
    async getCodeStatus( p ) {
      this.idisCode = ''
      this.codeId = ''
      this.qrParamValue = {}
      this.idisSync = true
      const res = await getCodeStatus( p )
      this.qrImgUrlText = res.value
      this.codeId = res.id
      this.idisSync = res.idisSync
      this.idisCode = res.idisCode
      this.qrImgUrlText = res.value
      if ( this.activeName == 'image' || this.activeName == 'file' ||
        this.activeName == 'audio' || this.activeName == 'video' || this.activeName == 'map' || this.activeName ==
        'wifi' || this.activeName == 'text' || this.activeName == 'link' || this.activeName == 'phone' ||
        this.activeName == 'callingCard' ) {
        await this.getQrBase64()

        this.isCreate = true
      }
    },
    qrCode() {
      if ( this.text == '' ) {
        this.$message( {
          type : 'warning',
          message : '内容为空，请输入'
        } )
        return
      }
      const params = {
        attrNameEn : this.activeName,
        attrValue : this.text,
        time : moment( Date.now() ).format( 'YYYY-MM-DD HH:mm:ss' )
      }

      if ( this.activeName === 'text' ) {
        const value = JSON.stringify( {
          content : this.text
        } )
        params['attrValue'] = `${window.location.origin}/showText?o=${value}`
      } else if ( this.activeName === 'phone' ) {
        this.text = this.qrForm.phone
        const value = JSON.stringify( {
          phone : this.qrForm.phone
        } )
        params['attrValue'] = `${window.location.origin}/showPhone?o=${value}`
      } else if ( this.activeName === 'link' ) {
        this.text = this.qrForm.link2
        const value = JSON.stringify( {
          url : this.qrForm.link2,
          type : this.qrForm.linkType
        } )
        params['attrValue'] = `${window.location.origin}/showUrl?o=${value}`
      } else if ( this.activeName === 'callingCard' ) {
        const value = JSON.stringify( {
          name : this.qrForm.name,
          tel : this.qrForm.mobile,
          title : this.qrForm.position,
          org : this.qrForm.company,
          email : this.qrForm.email,
          addr : this.qrForm.address,
          link : this.qrForm.link
        } )
        params['attrValue'] = `${window.location.origin}/showMecard?o=${value}`
      }

      if ( this.$store.getters.token && this.$store.getters.token != undefined ) {
        this.getCodeStatus( params )
      }
    },
    async getQrBase64( update = false ) {
      const p = {
        'detectSpecial' : false, // 是否码点形状作用于探测点
        'detectOutColor' : '', // 非自定义探测点图片情况下,探测点的外边框颜色
        'detectInColor' : '', // 非自定义探测点图片情况下,探测点的内边框颜色
        'drawPreColor' : '0,0,0', // 码点颜色
        'drawPreStyle' : 'RECT', // 码点形状
        'logo' : '', // logo地址
        'logoStyle' : 'NORMAL',
        'padding' : 20, // 边距
        'detectImg' : '', // 探测点图片地址
        'textAdd' : false, // 是否追加文字
        'textContent' : '',
        'textPos' : 'top', // 文字位置
        'textSize' : 16, // 文字大小
        'textColor' : '0,0,0', // 文字颜色
        'bgColor' : `255,255,255`, // 二维码背景
        'bgImg' : '', // 背景图
        'bgOpacity' : `1`, // 背景透明度
        'errorLevel' : 'H', // 容错级别
        'width' : 300, // 宽度
        'height' : 300, // 高度
        'idisContent' : '',
        'msg' : this.activeName == 'image' || this.activeName == 'file' ||
          this.activeName == 'audio' || this.activeName == 'video' || this.activeName == 'map' || this.activeName ==
          'wifi' || this.activeName == 'text' || this.activeName == 'link' || this.activeName == 'phone' ||
          this.activeName == 'callingCard' ? this.qrImgUrlText : this.text// 码内容
      }

      Object.assign( p, this.qrParamValue )
      if ( this.idisFlag && this.idisCode && this.idisSync ) {
        p.idisContent = this.idisCode
      } else if ( !this.idisFlag ) {
        p.idisContent = ''
      }

      getQrBase64( p ).then( res => {
        this.qrImgUrl = `data:image/png;base64,${res.data}`

        this.isShowQr = true

        const obj = {
          'img' : this.qrImgUrl,
          'time' : moment( Date.now() ).format( 'YYYY-MM-DD HH:mm:ss' ),
          'attrNameEn' : this.activeName,
          'attrValue' : this.text,
          'idisCode' : this.idisCode
        }

        if ( this.qrCreateList.length < 10 ) {
          if ( update ) {
            for ( let i = 0; i < this.qrCreateList.length; i++ ) {
              const qr = this.qrCreateList[i]
              if ( this.idisCode === qr.idisCode ) {
                this.qrCreateList[i].img = this.qrImgUrl
              }
            }
          } else {
            const arr = JSON.parse( JSON.stringify( this.qrCreateList ) )
            arr.unshift( obj )
            this.qrCreateList = arr
            this.$store.dispatch( 'saveQrList', arr )
          }
        }
      } )
    },
    // 图片上传
    beforeUpload( e ) {
      // const dom = document.getElementById('uploadImg')
      e = window.event || e
      const types = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/JPG', 'image/JPEG', 'image/PNG',
        'image/GIF']
      const fileList = e.target.files
      const sortArr = []
      for ( let i = 0; i < fileList.length; i++ ) {
        const isImg = types.includes( fileList[i].type )
        const isLt2M = fileList[i].size / 1024 / 1024 < 10
        if ( !isLt2M ) {
          this.$message( {
            type : 'warning',
            message : '上传图片大小不能超过 10MB!'
          } )
          e.target.value = ''
          return false
        }
        if ( !isImg ) {
          this.$message( {
            type : 'warning',
            message : '上传图片格式错误，仅限jpg，jpeg，png，gif格式!'
          } )
          e.target.value = ''
          return false
        }

        if ( isLt2M && isImg ) {
          sortArr.push( fileList[i] )
        }
      }
      var compare = function( a, b ) {
        const v1 = a.size
        const v2 = b.size
        if ( v1 < v2 ) {
          return -1
        } else if ( v1 > v2 ) {
          return 1
        } else {
          return 0
        }
      }
      const arrSort = sortArr.sort( compare )
      arrSort[arrSort.length - 1].loading = true
      arrSort.map( i => {
        this.uploadActionImg( i )
      } )
    },
    uploadActionImg( p ) {
      if ( p.loading ) {
        this.loadingUp = true
      }
      const fd = new FormData()
      const file = p
      const reader = new FileReader()
      reader.readAsDataURL( file )
      fd.append( 'file', file )
      uploadActionImg( fd ).then( res => {
        const obj = {
          url : res.data,
          name : res.fileName,
          size : res.size
        }
        this.upLoadImgs.fileList.push( obj )
        this.imgFiles.push( obj.url )
        if ( res.fileName == p.name && p.loading ) {
          this.loadingUp = false
        }
      } )
      document.getElementById( 'uploadImg' ).value = null
    },
    dragstart( index ) {
      this.dragIndex = index
    },

    // dragenter 和 dragover 事件的默认行为是拒绝接受任何被拖放的元素。
    // 因此，我们要在这两个拖放事件中使用`preventDefault`来阻止浏览器的默认行为
    dragenter( e, index ) {
      e.preventDefault()
      this.enterIndex = index
      if ( this.timeout !== null ) {
        clearTimeout( this.timeout )
      }
      // 拖拽事件的防抖
      this.timeout = setTimeout( () => {
        if ( this.dragIndex !== index ) {
          const source = this.upLoadImgs.fileList[this.dragIndex]
          this.upLoadImgs.fileList.splice( this.dragIndex, 1 )
          this.upLoadImgs.fileList.splice( index, 0, source )
          // 排序变化后目标对象的索引变成源对象的索引
          this.dragIndex = index
        }
      }, 100 )
    },
    dragover( e, index ) {
      e.preventDefault()
    },
    handleRemove( item ) {
      this.upLoadImgs.fileList.map( ( i, k ) => {
        if ( i.name == item.name ) {
          this.upLoadImgs.fileList.splice( k, 1 )
        }
      } )
      this.upLoadImgs.fileList.map( i => {
        this.imgFiles.push( i.url )
      } )
    },
    showPreviewImg() {
      document.documentElement.style.overflowY = 'hidden'
    },
    handleClickItem() {
      document.documentElement.style.overflowY = 'auto'
    },
    handlePictureCardPreview( file ) {
      this.dialogUpImageUrl = file.url
      this.dialogVisible = true
    },
    beforeFileUpload( e ) {
      e = window.event || e
      const types = ['application/msword', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain', 'application/vnd.ms-excel']
      const fileList = e.target.files
      const sortArr = []
      for ( let i = 0; i < fileList.length; i++ ) {
        const isFile = types.includes( fileList[i].type )
        const isLt2M = fileList[i].size / 1024 / 1024 < 500
        if ( !isLt2M ) {
          this.$message( {
            type : 'warning',
            message : '上传文件大小不能超过 500MB!'
          } )
          e.target.value = ''
          return false
        }
        if ( !isFile ) {
          this.$message( {
            type : 'warning',
            message : '上传文件格式错误，仅限doc，docx，csv，xls，xlsx，pdf，txt格式'
          } )
          e.target.value = ''
          return false
        }
        if ( isLt2M && isFile ) {
          sortArr.push( fileList[i] )
        }
      }
      var compare = function( a, b ) {
        const v1 = a.size
        const v2 = b.size
        if ( v1 < v2 ) {
          return -1
        } else if ( v1 > v2 ) {
          return 1
        } else {
          return 0
        }
      }
      const arrSort = sortArr.sort( compare )
      arrSort[arrSort.length - 1].loading = true
      arrSort.map( i => {
        this.uploadActionFile( i )
      } )
    },
    uploadActionFile( p ) {
      if ( p.loading ) {
        this.loadingUp = true
      }
      const fd = new FormData()
      const file = p
      const reader = new FileReader()
      reader.readAsDataURL( file )
      fd.append( 'file', file )
      uploadActionFile( fd ).then( res => {
        const obj = {
          url : res.data,
          name : res.fileName,
          size : res.size
        }
        this.upLoadFiles.fileList.push( obj )
        if ( res.fileName == p.name && p.loading ) {
          this.loadingUp = false
        }
      } )
      document.getElementById( 'uploadFile' ).value = null
    },
    // 音频判断
    beforeAudioUpload( e ) {
      e = window.event || e
      const types = ['audio/mpeg', 'audio/wav']
      const fileList = e.target.files
      const sortArr = []
      for ( let i = 0; i < fileList.length; i++ ) {
        const isFile = types.includes( fileList[i].type )
        const isLt2M = fileList[i].size / 1024 / 1024 < 100
        if ( !isLt2M ) {
          this.$message( {
            type : 'warning',
            message : '上传音频大小不能超过 100MB!'
          } )
          e.target.value = ''
          return false
        }
        if ( !isFile ) {
          this.$message( {
            type : 'warning',
            message : '上传音频格式错误，仅限mp3，wav格式'
          } )
          e.target.value = ''
          return false
        }
        if ( isLt2M && isFile ) {
          sortArr.push( fileList[i] )
        }
      }
      var compare = function( a, b ) {
        const v1 = a.size
        const v2 = b.size
        if ( v1 < v2 ) {
          return -1
        } else if ( v1 > v2 ) {
          return 1
        } else {
          return 0
        }
      }
      const arrSort = sortArr.sort( compare )
      arrSort[arrSort.length - 1].loading = true
      arrSort.map( i => {
        this.uploadActionAudio( i )
      } )
    },
    uploadActionAudio( p ) {
      if ( p.loading ) {
        this.loadingUp = true
        this.isUplaoding = true
      }

      const fd = new FormData()
      const file = p
      const reader = new FileReader()
      reader.readAsDataURL( file )
      fd.append( 'file', file )
      uploadActionAudio( fd ).then( res => {
        const obj = {
          url : res.data,
          name : res.fileName,
          size : res.size
        }
        this.upLoadAudios.fileList.push( obj )
        if ( res.fileName == p.name && p.loading ) {
          this.loadingUp = false
          this.isUplaoding = false
        }
      } )
      document.getElementById( 'uploadAudio' ).value = null
    },
    // 视频判断
    beforeVideoUpload( e ) {
      e = window.event || e
      const types = ['video/mp4', 'video/3gpp', 'application/x-mpegurl']
      const fileList = e.target.files
      const sortArr = []
      for ( let i = 0; i < fileList.length; i++ ) {
        const isFile = types.includes( fileList[i].type )
        const isLt2M = fileList[i].size / 1024 / 1024 < 100
        if ( !isLt2M ) {
          this.$message( {
            type : 'warning',
            message : '上传视频大小不能超过 100MB!'
          } )
          e.target.value = ''
          return false
        }
        if ( !isFile ) {
          this.$message( {
            type : 'warning',
            message : '上传视频格式错误，仅限mp4，3gp，m3u8格式'
          } )
          e.target.value = ''
          return false
        }
        if ( isLt2M && isFile ) {
          sortArr.push( fileList[i] )
        }
      }
      var compare = function( a, b ) {
        const v1 = a.size
        const v2 = b.size
        if ( v1 < v2 ) {
          return -1
        } else if ( v1 > v2 ) {
          return 1
        } else {
          return 0
        }
      }
      const arrSort = sortArr.sort( compare )
      arrSort[arrSort.length - 1].loading = true
      arrSort.map( i => {
        this.uploadActionVideo( i )
      } )
    },
    uploadActionVideo( p ) {
      if ( p.loading ) {
        this.loadingUp = true
        this.isUplaoding = true
      }

      const fd = new FormData()
      const file = p
      const reader = new FileReader()
      reader.readAsDataURL( file )
      fd.append( 'file', file )
      uploadActionVideo( fd ).then( res => {
        const obj = {
          url : res.data,
          name : res.fileName,
          size : res.size
        }
        this.upLoadVideos.fileList.push( obj )
        if ( res.fileName == p.name && p.loading ) {
          this.loadingUp = false
          this.isUplaoding = false
        }
      } )
      document.getElementById( 'uploadVideo' ).value = null
    },
    dragstartF( index ) {
      this.dragIndex = index
    },

    // dragenter 和 dragover 事件的默认行为是拒绝接受任何被拖放的元素。
    // 因此，我们要在这两个拖放事件中使用`preventDefault`来阻止浏览器的默认行为
    dragenterF( e, index ) {
      e.preventDefault()
      this.enterIndex = index
      if ( this.timeout !== null ) {
        clearTimeout( this.timeout )
      }
      // 拖拽事件的防抖
      this.timeout = setTimeout( () => {
        if ( this.dragIndex !== index ) {
          if ( this.activeName === 'file' ) {
            const source = this.upLoadFiles.fileList[this.dragIndex]
            this.upLoadFiles.fileList.splice( this.dragIndex, 1 )
            this.upLoadFiles.fileList.splice( index, 0, source )
            // 排序变化后目标对象的索引变成源对象的索引
            this.dragIndex = index
          }
          if ( this.activeName === 'audio' ) {
            const source = this.upLoadAudios.fileList[this.dragIndex]
            this.upLoadAudios.fileList.splice( this.dragIndex, 1 )
            this.upLoadAudios.fileList.splice( index, 0, source )
            // 排序变化后目标对象的索引变成源对象的索引
            this.dragIndex = index
          }
          if ( this.activeName === 'video' ) {
            const source = this.upLoadVideos.fileList[this.dragIndex]
            this.upLoadVideos.fileList.splice( this.dragIndex, 1 )
            this.upLoadVideos.fileList.splice( index, 0, source )
            // 排序变化后目标对象的索引变成源对象的索引
            this.dragIndex = index
          }
        }
      }, 100 )
    },
    dragoverF( e, index ) {
      e.preventDefault()
    },
    handleFileRemove( item ) {
      this.upLoadFiles.fileList.map( ( i, k ) => {
        if ( i.name == item.name ) {
          this.upLoadFiles.fileList.splice( k, 1 )
        }
      } )
    },
    handleAudioRemove( item ) {
      this.upLoadAudios.fileList.map( ( i, k ) => {
        if ( i.name == item.name ) {
          this.upLoadAudios.fileList.splice( k, 1 )
        }
      } )
    },
    handleVideoRemove( item ) {
      this.upLoadVideos.fileList.map( ( i, k ) => {
        if ( i.name == item.name ) {
          this.upLoadVideos.fileList.splice( k, 1 )
        }
      } )
    },
    handleCommand( command ) {
      if ( command == '1' ) {
        this.isEmail = true
      } else if ( command == '2' ) {
        this.isLink = true
      } else if ( command == '3' ) {
        this.isAddress = true
      }
      this.dropdownList.map( ( i, k ) => {
        if ( i.key == command ) {
          this.dropdownList.splice( k, 1 )
        }
      } )
    },
    delCard( t ) {
      if ( t == 1 ) {
        this.isEmail = false
        this.qrForm.email = ''
        this.$refs.qrForm.clearValidate( 'email' )
        this.dropdownList.push( { key : '1', name : '邮箱' } )
      } else if ( t == 2 ) {
        this.isLink = false
        this.qrForm.link = ''
        this.$refs.qrForm.clearValidate( 'link' )
        this.dropdownList.push( { key : '2', name : '网址' } )
      } else if ( t == 3 ) {
        this.isAddress = false
        this.qrForm.address = ''
        this.$refs.qrForm.clearValidate( 'address' )
        this.dropdownList.push( { key : '3', name : '地址' } )
      }
    },
    // 地图生码
    onSearchResult( pois ) {
      console.log( 'onSearchResult>>', pois )
      this.markers = []
      let latSum = 0
      let lngSum = 0
      if ( pois.length > 0 ) {
        if ( pois.length == 1 ) {
          this.mapObj = {
            longitude : pois[0].lng,
            latitude : pois[0].lat,
            address : pois[0].name
          }
        }
        console.log( this.mapObj )
        pois.forEach( poi => {
          const { lng, lat } = poi
          lngSum += lng
          latSum += lat
          this.markers.push( [poi.lng, poi.lat] )
        } )
        const center = {
          lng : lngSum / pois.length,
          lat : latSum / pois.length
        }
        this.mapCenter = [center.lng, center.lat]
      }
    },
    jumpToForm( v ) {
      if ( v === 'create' ) {
        window.open( `${window.location.origin}/form/`, '_blank' )
      } else {
        this.$router.push( { path : '/templateForm' } )
        // window.open( `${window.location.origin}/form/template`, '_blank' )
      }
    },
    // async listNewsList() {
    //   const res = await listNewsList()
    //   res.ret.map(i => {
    //     i.addTime = i.addTime.split(' ')[0]
    //   })
    //   this.newsData = res.ret
    // },
    // async getQuestions() {
    //   const res = await openListAnswer({
    //     pageNum: 1,
    //     pageSize: 3
    //   })
    //   res.rows.map(i => {
    //     if (parseInt(i.createBy)) {
    //       i.createBy = i.createBy.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2')
    //     }
    //   })
    //   this.questionList = res.rows
    // },
    // async getSecondNodes() {
    //   const res = await getSecondNodes()
    //   res.rows.map(i => {
    //     i.createTime = i.createTime.split(' ')[0]
    //   })
    //   this.secondNodes = res.rows
    // },
    // goToMore(v) {
    //   if (v === 1) {
    //     window.open(process.env.CMS_NEWS_URL)
    //   } else if (v === 2) {
    //     eventBus.$emit('activeIndex', '6')
    //     this.$router.push({ path: '/community' })
    //   }
    // },
    // goToComm() {
    //   eventBus.$emit('activeIndex', '6')
    // },
    qrParam( v ) {
      this.qrParamValue = v
    },
    sureQr( v ) {
      this.qrImgUrl = v
      const arr = JSON.parse( JSON.stringify( this.qrCreateList ) )
      arr.map( i => {
        if ( i.attrValue == this.text ) {
          i.img = this.qrImgUrl
        }
      } )
      this.qrCreateList = arr
      this.$store.dispatch( 'saveQrList', arr )
    },
    downloadSet() {
      this.$refs.beautifyQr.openDownLoad()
    },
    openEditQr() {
      let idisCode = ''
      if ( this.idisFlag ) {
        idisCode = this.idisCode
      }
      this.$refs.beautifyQr.openDialog( idisCode )
    },
    downloadQr() {
      const downLoadSet = JSON.parse( localStorage.getItem( 'downLoadSet' ) ) ? JSON.parse(
        localStorage.getItem( 'downLoadSet' ) ) : this.downLoadSet
      console.log( 'dddd>', downLoadSet )
      if ( downLoadSet.type == 'png' ) {
        const aLink = document.createElement( 'a' )
        aLink.style.display = 'none'
        aLink.href = this.qrImgUrl
        aLink.download = '二维码.png'
        document.body.appendChild( aLink )
        aLink.click()
        document.body.removeChild( aLink )
      } else {
        const el = document.createElementNS( 'http://www.w3.org/2000/svg', 'svg' )
        el.setAttribute( 'xmlns', 'http://www.w3.org/2000/svg' )
        el.setAttribute( 'width', downLoadSet.size )
        el.setAttribute( 'height', downLoadSet.size )
        const elImg = document.createElementNS( 'http://www.w3.org/2000/svg', 'image' )
        elImg.setAttributeNS( 'http://www.w3.org/1999/xlink', 'xlink:href', this.qrImgUrl )
        elImg.setAttribute( 'width', '100%' )
        elImg.setAttribute( 'height', '100%' )
        el.appendChild( elImg )
        const ele = new XMLSerializer().serializeToString( el )
        const svgImg = `data:image/svg+xml;base64,${window.btoa( ele )}`
        const create_a_dom = document.createElement( 'a' )
        document.body.append( create_a_dom )
        create_a_dom.href = svgImg
        create_a_dom.download = '二维码'
        create_a_dom.click()
        document.body.removeChild( create_a_dom )
      }
    },
    downQR( item ) {
      const aLink = document.createElement( 'a' )
      aLink.style.display = 'none'
      aLink.href = item.img
      aLink.download = '二维码.png'
      document.body.appendChild( aLink )
      aLink.click()
      document.body.removeChild( aLink )
    },
    saveToAdmin() {
      if ( !this.$store.getters.token && this.$store.getters.token == undefined ) {
        eventBus.$emit( 'loginOrRegister', 'openLogin' )
      }
    },
    saveQrToUser() {
      const arr = []
      this.qrCreateList.map( i => {
        const obj = {
          'attrNameEn' : i.attrNameEn,
          'attrValue' : i.attrValue,
          'time' : i.time
        }
        arr.push( obj )
      } )
      saveToUser( arr ).then( res => {
        if ( res.code == 200 ) {
          this.$message( {
            type : 'success',
            message : '保存成功'
          } )
        }
      } )
    },
    toBigScreen() {
      this.$router.push( { path : '/map' } )
    },
    toBigScreen1() {
      this.$router.push( { path : '/scanMap' } )
    },
    toPlainText( html ) {
      return html?.replace( /<[^>]*>/g, '' ).replace( /[\r\n\s]/g, '' ).trim()
    },
    clickJieMa() {
      this.typeList.map( i => {
        i.isActive = false
      } )
      this.resetCode()
      this.activeName = 'jieMa'
    },
    handleJieMaClick( e ) {
      console.log( e )
      if ( e.name == 'first' ) {
        this.jieMaType = e.name
        this.errorTips = '内容超150字，不易扫描'
      } else if ( e.name == 'second' || e.name == 'third' ) {
        this.jieMaType = e.name
        this.errorTips = ''
      }
      this.jieMaUrl = ''
    },
    jieXiMa() {
      qrcodeParser( this.upJieMaImg ).then( res => {
        console.log( res )
        this.jieMaDia = true
        this.jieMaInfo = res
        if ( this.jieMaType === 'first' ) {
          this.errorTips = '内容超150字，不易扫描'
        } else if ( this.jieMaType === 'second' ) {
          this.errorTips = ''
        }
      } ).catch( ( err ) => {
        console.log( 'error', err )
        console.log( 'error', err )
        // this.errorTips = '无法识别出该图片中的二维码内容'
        // 尝试后台接口解码
        if ( this.decodeQrTxt && this.decodeQrTxt != '' ) {
          this.jieMaInfo = this.decodeQrTxt
          this.jieMaDia = true
        } else {
          this.errorTips = '无法识别出该图片中的二维码内容'
        }
      } )
    },
    handleJieMaSuccess( res, file ) {
      this.upJieMaImg = file.raw
      if ( res.code == 200 && res.qrTxt ) {
        this.decodeQrTxt = res.qrTxt
      }
      this.jieXiMa()
    },
    beforeJieMaUpload( file ) {
      const types = ['image/jpg', 'image/jpeg', 'image/png', 'image/JPG', 'image/JPEG', 'image/PNG']
      const isImg = types.includes( file.type )

      const isLt2M = file.size / 1024 / 1024 < 20
      if ( !isLt2M ) {
        this.$message( {
          type : 'warning',
          message : '上传图片大小不能超过 20M!'
        } )
        return false
      }
      if ( !isImg ) {
        this.$message( {
          type : 'warning',
          message : '上传Logo格式错误，仅限jpg，jpeg，png格式'
        } )
        return false
      }
      return isLt2M && isImg
    },
    handleJieMaClose() {
      this.jieMaDia = false
      this.jieMaInfo = ''
    },
    jieMaCode() {
      this.text = this.jieMaInfo
      this.activeName = 'text'
      this.typeList.map( i => {
        if ( i.key == 'text' ) {
          i.isActive = true
        } else {
          i.isActive = false
        }
      } )
      setTimeout( () => {
        this.createQrCode()
        this.handleJieMaClose()
      }, 50 )
    },
    copyJieMa() {
      const url = this.jieMaInfo // 后台接口返回的链接地址
      const inputNode = document.createElement( 'input' ) // 创建input
      inputNode.value = url // 赋值给 input 值
      document.body.appendChild( inputNode ) // 插入进去
      inputNode.select() // 选择对象
      document.execCommand( 'Copy' ) // 原生调用执行浏览器复制命令
      inputNode.className = 'oInput'
      inputNode.style.display = 'none' // 隐藏
      this.$message.success( '复制成功' )
    },
    enterJieMa() {
      if ( !this.jieMaUrl || this.jieMaUrl === '' ) {
        this.$message.warning( '请输入二维码图片网址' )
        return
      }
      // else if (this.jieMaUrl.indexOf('.png') < 0 && this.jieMaUrl.indexOf('.PNG') < 0 && this.jieMaUrl.indexOf('.jpeg') < 0 && this.jieMaUrl.indexOf('.JPEG') < 0 && this.jieMaUrl.indexOf('.jpg') < 0 && this.jieMaUrl.indexOf('.JPG') < 0) {
      //   this.$message.warning('二维码图片网址格式错误')
      //   return
      // }
      //
      console.log( 324, this.jieMaUrl )
      this.imgToBase64( this.jieMaUrl, base => {
        const file = this.base64toFile( base, '图片' )
        this.upJieMaImg = file
        this.jieXiMa()
      } )
    },
    imgToBase64( url, cb ) {
      var canvas = document.createElement( 'canvas' )
      var ctx = canvas.getContext( '2d' )
      var img = new Image()
      img.crossOrigin = 'anonymous'
      img.onload = function() {
        canvas.height = img.height
        canvas.width = img.width
        ctx.drawImage( img, 0, 0 )
        this.dataURL = canvas.toDataURL( 'image/png' )
        console.log( 'dddd??', this.dataURL )
        cb && cb( this.dataURL )
        canvas = null
      }
      img.src = url
      if ( !this.dataURL || this.dataURL === undefined ) {
        this.errorTips = '无法识别出该图片中的二维码内容'
      }
      console.log( 3434, this.dataURL )
    },
    // base64转file对象
    base64toFile( base, filename ) {
      console.log( 'base>>>', base )
      if ( base.indexOf( 'data:image/' ) > -1 ) {
        var arr = base.split( ',' )
        var mime = arr[0].match( /:(.*?);/ )[1]
        var suffix = mime.split( '/' )[1]
        var bstr = atob( arr[1] )
        var n = bstr.length
        var u8arr = new Uint8Array( n )
        while ( n-- ) {
          u8arr[n] = bstr.charCodeAt( n )
        }
        // 转换成file对象
        return new File( [u8arr], `${filename}.${suffix}`, { type : mime } )
      } else {
        this.errorTips = '无法识别出该图片中的二维码内容'
      }
    },
    openCamera() {
      this.openScan()
    },
    handleCloseVideo() {
      this.openVideo = false
      this.codeReader.reset() // 关闭摄像头
    },
    async openScan() {
      const _this = this
      _this.codeReader
        .getVideoInputDevices() // 老版本listVideoInputDevices()
        .then( ( videoInputDevices ) => {
          console.log( videoInputDevices, 'videoInputDevices' )
          if ( videoInputDevices && videoInputDevices.length > 0 ) {
            if ( videoInputDevices.length > 1 ) {
              videoInputDevices.reverse()
            } // 防止先唤出前摄像头
            _this.videoInputDevices = videoInputDevices
            _this.currentVideoInputDevice = videoInputDevices[0]
            _this.openVideo = true
          } else {
            _this.errorTips = '未检测到摄像头，请使用其他方式解码'
          }
        } )
        .catch( ( err ) => {
          console.log( err )
          _this.errorTips = '未检测到摄像头，请使用其他方式解码'
        } )
    },
    decodeFromInputVideo() {
      const _this = this
      _this.codeReader.reset()
      // 多次
      _this.codeReader.decodeFromVideoDevice(
        _this.currentVideoInputDevice.deviceId,
        'videoJieMa',
        ( result, err ) => {
          if ( result ) {
            _this.decodeResult = result
          }
          if ( err ) {
            if ( err instanceof ChecksumException ) {
              console.log(
                "A code was found, but it's read value was not valid."
              )
            }
            if ( err instanceof FormatException ) {
              console.log( 'A code was found, but it was in a invalid format.' )
            }
          }
        }
      )
    },

    successDecode() {
      this.jieMaInfo = this.decodeResult.text
      this.openVideo = false
      this.jieMaDia = true
      this.codeReader.reset() // 关闭摄像头
    },
    pasteMe( e ) {
      var clipboardData = e.clipboardData // IE
      if ( !clipboardData ) {
        // chrome
        clipboardData = e.originalEvent.clipboardData
      }
      var items = ''
      items = ( e.clipboardData || window.clipboardData ).items
      let file = null
      if ( !items || items.length === 0 ) {
        this.$message.error( '当前浏览器不支持本地或请打开图片再复制' )
        return
      }
      // 搜索剪切板items
      for ( let i = 0; i < items.length; i++ ) {
        // if (items[i].type.indexOf('image') !== -1) {//此行代码注释掉，可以上传所有类型的文件
        file = items[i].getAsFile()
        // break;
        // }
      }
      if ( file ) { // 对复制黏贴的类型进行判断，若是非文件类型，比如复制黏贴的文字，则不会调用上传文件的函数
        console.log( 23424, file )
        this.upJieMaImg = file
        this.jieXiMa()
      }
    }
  }
}

